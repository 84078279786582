(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["hu"] = {
            name: "hu",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": " ",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "",
                    abbr: "",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": " ",
                    ".": ",",
                    groupSize: [3],
                    symbol: "Ft"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["vasárnap","hétfő","kedd","szerda","csütörtök","péntek","szombat"],
                        namesAbbr: ["V","H","K","Sze","Cs","P","Szo"],
                        namesShort: ["V","H","K","Sze","Cs","P","Szo"]
                    },
                    months: {
                        names: ["január","február","március","április","május","június","július","augusztus","szeptember","október","november","december"],
                        namesAbbr: ["jan.","febr.","márc.","ápr.","máj.","jún.","júl.","aug.","szept.","okt.","nov.","dec."]
                    },
                    AM: ["de.","de.","DE."],
                    PM: ["du.","du.","DU."],
                    patterns: {
                        d: "yyyy. MM. dd.",
                        D: "yyyy. MMMM d., dddd",
                        F: "yyyy. MMMM d., dddd H:mm:ss",
                        g: "yyyy. MM. dd. H:mm",
                        G: "yyyy. MM. dd. H:mm:ss",
                        m: "MMMM d.",
                        M: "MMMM d.",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "H:mm",
                        T: "H:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "yyyy. MMMM",
                        Y: "yyyy. MMMM"
                    },
                    "/": ". ",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
